import React, { useState, useEffect } from 'react';
import Typewriter from 'typewriter-effect';
import './index.scss';

const LoopingText = () => (
  <div>
    <h4 className="loop-text">
      <Typewriter
        options={{
          deleteSpeed: 20,
          delay: 30,
          loop: true,
        }}
        onInit={typewriter => {
          typewriter
            .pauseFor(900)
            .typeString("I'm a software engineer at AWS.")
            .pauseFor(1500)
            .deleteChars(25)
            .typeString('MSCS student at PSU.')
            .pauseFor(1500)
            .start();
        }}
      />
    </h4>
  </div>
);

export default LoopingText;
